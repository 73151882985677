<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Template Surat</h3>
            <hr class="m-t-0 m-b-40" />

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                  >
                    Unit Kerja / OPD
                    <small style="color: red" v-if="editMode">*</small>
                  </label>
                  <div
                    :class="['col-md-9', { invalid: dropdown.isinvalidInduk }]"
                  >
                    <Multiselect
                      placeholder="Type to Search"
                      select-label
                      :value="payload.parent"
                      track-by="value"
                      label="text"
                      :options="dropdown.parentList"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @input="onChangeParent"
                      @search-change="parentFind"
                    ></Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="dropdown.isinvalidInduk"
                      >Must have at least one value</label
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <label class="col-md-3 text-right">
                    Jenis Surat
                    <small style="color: red">*</small>
                  </label>
                  <div
                    class="col-md-9 text-right"
                    :class="[{ invalid: isInvalidUnitKerja }]"
                  >
                    <Multiselect
                      v-model="payload.unit"
                      label="text"
                      track-by="value"
                      :placeholder="$t('choose_receiver_unit')"
                      open-direction="bottom"
                      :options="filteredUkerList"
                      :value="dropdownUnitKerja.value"
                      :searchable="true"
                      :loading="dropdownUnitKerja.isLoadingUnitKerja"
                      :internal-search="false"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :options-limit="300"
                      :max-height="600"
                      :show-no-results="true"
                      :hide-selected="true"
                      @input="onChangeUnitKerja"
                      @close="onTouchUnitKerja"
                      @search-change="unitKerja"
                    >
                      <span slot="noResult">{{ $t("data_not_found") }}</span>
                    </Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isInvalidUnitKerja"
                      >{{ $t("at_least_one") }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.keterangan"
                  name="keterangan"
                  type="text"
                  label="Keterangan"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label :class="['control-label']"
                    >{{ $t("file") }} {{ $t("attachment") }}</label
                  >
                  <h6 v-if="!files.length">{{ $t("no_file") }}</h6>
                  <table v-else class="table table-stripped">
                    <tr>
                      <th>{{ $t("file") }}</th>
                      <th>{{ $t("action") }}</th>
                    </tr>
                    <tr v-for="file in files" v-bind:key="file.filename">
                      <td>
                        {{
                          file.filepath !== undefined
                            ? file.filename
                            : file.name
                        }}
                      </td>
                      <td>
                        <span v-if="file.filepath !== undefined">
                          <a
                            class="btn btn-success text-white"
                            @click="download(file.uuid, file.filename)"
                            ><i class="fa fa-download text-white"></i>
                          </a>
                          <a
                            class="btn btn-info text-white"
                            @click="prev(file.uuid)"
                            ><i class="fa fa-eye text-white"></i>
                          </a>
                        </span>
                        <i
                          ><b>{{ $t("not_saved") }} &nbsp;&nbsp;</b></i
                        >
                        <a
                          class="btn btn-danger text-white"
                          @click="removeAttachment(file.uuid)"
                        >
                          <i class="fa fa-trash-o text-white"></i>
                        </a>
                      </td>
                    </tr>
                  </table>
                  <Gallery :uploader="uploader" />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button
                      type="submit"
                      class="btn btn-success"
                      title="Submit"
                    >
                      Submit</button
                    >&nbsp;
                    <button
                      type="button"
                      @click="goBack()"
                      class="btn btn-inverse"
                      title="Cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
        <ModalDefault
          v-show="isVisible"
          :title="modalTitle"
          :content="modalContent"
          :action="ActionChangeable"
          :confirmation="isConfirmation"
          @close="onClose"
        />
      </div>
    </div>
    <Modal
      v-show="isModalVisible"
      @close="closeModal"
      @action="onDelete(deleteData)"
    />
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import axios from "axios";
import Modal from "@/components/modal/modal.vue";

export default {
  components: {
    Input,
    Multiselect,
    RotateSquare5,
    Modal,
  },
  computed: {
    isInvalidUnitKerja() {
      return (
        this.dropdownUnitKerja.isTouched &&
        this.dropdownUnitKerja.value.length === 0
      );
    },
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.templateSurat;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    filteredUkerList() {
      let list = this.dropdownUnitKerja.ukerList;
      return list;
    },
  },
  data() {
    const uploader = this.fineUploader(this, "template_surat");

    return {
      uploader,
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      payload: {
        // kode: "",
        // is_superior: "",
        parent: "",
        unit: {},
        name: "",
        keterangan: "",
        file: "",
      },
      dropdown: {
        parentList: [],
        isinvalidInduk: false,
      },
      dropdownUnitKerja: {
        isTouched: false,
        ukerList: [],
        value: [],
        isLoadingUnitKerja: false,
      },
      isDisabled: false,
      isLoading: false,
      myFile: [],
      files: [],
      isModalVisible: false,
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isConfirmation: false,
      ActionChangeable: null,
      disabledButton: false,
    };
  },
  async mounted() {
    var query = "";
    this.parentFind(query);
    this.initialize();
    this.unitKerja(query);
  },

  methods: {
    removeAttachment(uuid) {
      let vm = this;
      let files = vm.uploader.methods.getUploads();
      files.map((element) => {
        if (element.uuid == uuid) {
          let folder;
          vm.files.map((ele) => {
            if (ele.uuid == uuid) {
              folder = ele.folder;
            }
          });
          vm.uploader.methods.setDeleteFileParams(
            { folder: folder },
            element.id
          );
          vm.uploader.methods.deleteFile(element.id);
        }
      });
      vm.files = vm.files.filter((element) => {
        return element.uuid != uuid;
      });
    },
    onClose() {
      this.isVisible = false;
    },
    showModal(option) {
      this.isVisible = true;
      if (option.type == "submit") {
        if (option.data == 0) {
          this.modalTitle = "Simpan Data?";
          this.modalContent = "<p>Apakah anda yakin ingin menyimpan surat?</p>";
          this.isConfirmation = true;
          this.ActionChangeable = this.submit;
        } else if (option.data == 1) {
          this.modalTitle = "Kirim Data?";
          this.modalContent = "<p>Apakah anda yakin ingin mengirim surat?</p>";
          this.isConfirmation = true;
          this.ActionChangeable = this.submit;
        }
      } else if (option.type == "cancel") {
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },
    onChangeUnitKerja(value) {
      this.dropdownUnitKerja.value = value;
    },
    onTouchUnitKerja() {
      this.dropdownUnitKerja.isTouched = true;
    },
    async initialize() {
      try {
        if (this.editMode) {
          axios
            .get(`/templateSurat/${this.$route.params.id}`)
            .then((response) => {
              const state = {
                loaded: true,
              };
              this.$store.commit("templateSurat/STATE", state);
              this.edit(response.data.data);
            });
        } else {
          this.$store.dispatch("templateSurat/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        // name: data.name,
        // kode: data.kode,
        // is_superior: data.is_superior,
        unit: data.jenis_id,
        parent: data.unit_id,
        keterangan: data.keterangan,
        files: data.files,
      };
      this.dropdownUnitKerja.value = data.unitPos;
      this.files = data.files;
    },
    unitKerja(query) {
      const mv = this;
      mv.dropdownUnitKerja.isLoadingUnitKerja = true;
      let address = "";
      // if(mv.payload.tuk.value == 3){
      //   address = `kategoriAsalSurat/listByLevel?s=${query ? query : ""}`
      // }else{
      address = `clasificationdok/list?s=${query ? query : ""}`;
      // }
      axios
        .get(address)
        .then((res) => {
          mv.dropdownUnitKerja.ukerList = res.data.items;
          mv.dropdownUnitKerja.isLoadingUnitKerja = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            mv.$store.dispatch("auth/logout");
          }
        });
    },
    closeModal() {
      this.isModalVisible = false;
    },
    openModal() {
      this.isModalVisible = true;
    },
    onChangeParent(value) {
      this.payload.parent = value;
    },
    parentFind(query) {
      this.isLoading = true;
      axios
        .get(`units/list?s=${query}`)
        .then((res) => {
          this.dropdown.parentList = res.data.items;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    onSelect(option) {
      if (option === "Disable me!") this.isDisabled = true;
    },
    goBack() {
      this.$store.dispatch("templateSurat/onCancel");
    },
    submit() {
      let _this = this;
      let that = _this;
      if (this.payload.file) {
        this.myFile = this.payload.file.split(",");
      }
      const payload = {
        id: this.$route.params.id,
        parent: parseInt(this.payload.parent.value),
        keterangan: this.payload.keterangan,
        // name: this.payload.name,
        // kode: this.payload.kode,
        // is_superior: this.payload.is_superior,
        unit: parseInt(this.payload.unit.value),
        files: this.files,
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (!that.files.length) {
            const message = {
              title: "Error",
              text: "File harus di isi",
              type: "error",
            };
            that.$store.commit("addNotification", message);
            that.isVisible = false;
            return;
          }
          const state = {
            loaded: false,
          };
          this.$store.commit("templateSurat/STATE", state);
          if (this.editMode) {
            this.$store
              .dispatch("templateSurat/submitEdit", {
                payload,
              })
              .then((result) => {
                const state = {
                  loaded: true,
                };
                this.$store.commit("templateSurat/STATE", state);
                this.showModal({
                  type: "information",
                  document_number: result.data.data.document_number,
                });
                this.isVisible = true;
              })
              .catch(() => {
                const state = {
                  loaded: true,
                };
                this.$store.commit("templateSurat/STATE", state);
                this.isVisible = false;
              });
          } else {
            this.$store.dispatch("templateSurat/submitAdd", payload);
          }
        } else {
          this.isVisible = false;
          window.scrollTo(0, 0);
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

